// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-order-management-an-important-tool-for-your-e-commerce-business-js": () => import("./../../../src/pages/order-management-an-important-tool-for-your-e-commerce-business.js" /* webpackChunkName: "component---src-pages-order-management-an-important-tool-for-your-e-commerce-business-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-top-three-trends-that-will-shape-the-future-of-erp-business-js": () => import("./../../../src/pages/top-three-trends-that-will-shape-the-future-of-erp-business.js" /* webpackChunkName: "component---src-pages-top-three-trends-that-will-shape-the-future-of-erp-business-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-why-omnichannel-is-future-of-customer-interaction-js": () => import("./../../../src/pages/why-omnichannel-is-future-of-customer-interaction.js" /* webpackChunkName: "component---src-pages-why-omnichannel-is-future-of-customer-interaction-js" */),
  "component---src-templates-template-js": () => import("./../../../src/templates/template.js" /* webpackChunkName: "component---src-templates-template-js" */)
}

